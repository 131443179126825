import * as React from 'react';
import Tabs from 'components/CustomTabs/CustomTabs';
import SettingPanel from './OverviewCardSettingPanel';
import PropTypes from 'prop-types';
import $scope from 'consts/scope';
import AlertDismissible from 'components/Setting/AlertDismissible';

class TabSettingPanel extends React.Component {
  static defaultProps = {
    withoutCardHeader: false,
  };

  render() {
    const { data, title, withoutCardHeader, headerColor, controlElement } = this.props;
    let tabsData = Object.keys(data).map(key => {
      return {
        tabName: key,
        tabIcon: null,
        tabContent: (
          <div>
            <div>
              <AlertDismissible name={key} />
            </div>
            <div>
              <SettingPanel
                data={data[key]}
                title={`${withoutCardHeader ? '' : key}`}
                description={$scope.tabDescriptions[key]}
                noCardHeader={false}
              />
            </div>
          </div>
        ),
      };
    });
    const propsObject = {};
    if (title) propsObject.title = title;
    if (headerColor) propsObject.headerColor = headerColor;
    if (controlElement) propsObject.controlElement = controlElement;

    return (
      <Tabs
        headerColor="dark"
        {...propsObject}
        tabs={tabsData}
        onTabChanged={selectedTabName => {
          if (this.props.onTabChanged) {
            this.props.onTabChanged(selectedTabName);
          }
        }}
      />
    );
  }
}

TabSettingPanel.propTypes = {
  withoutCardHeader: PropTypes.bool,
};

export default TabSettingPanel;
