import * as React from 'react';
import $scope from 'consts/scope';
import { Alert, AlertTitle } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

export default function AlertDismissible(props) {
  const [show, setShow] = React.useState(true);
  const { name = '' } = props;

  if (localStorage.getItem(name + 'guiAlert') !== 'false' && $scope.alertContents[name]) {
    return (
      <>
        <Alert show={show} severity="success">
          <AlertTitle>{$scope.alertContents[name][0]}</AlertTitle>
          <p>{$scope.alertContents[name][1]}</p>
          <hr />
          <div className="d-flex justify-content-end">
            <Button
              onClick={() => {
                setShow(false);
                localStorage.setItem(name + 'guiAlert', 'false');
              }}
              variant="outline-success"
            >
              Close
            </Button>
          </div>
        </Alert>
      </>
    );
  } else {
    return (
      <>
        <div></div>
      </>
    );
  }
}

AlertDismissible.propTypes = {
  name: PropTypes.string,
};
