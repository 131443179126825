import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import CopyIcon from '@material-ui/icons/FilterNone';
import HelpIcon from '@material-ui/icons/Help';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import { blackColor } from 'assets/jss/material-dashboard-react';
import { grayColor } from 'assets/jss/material-dashboard-react';
import { whiteColor } from 'assets/jss/material-dashboard-react';
import { isNumber } from 'util';

import $scope from 'consts/scope';
import _ from 'lodash';

const Decimal = require('decimal.js');
var autoParse = require('auto-parse');
const BlackIconButton = withStyles(theme => ({
  root: {
    color: 'rgba(255,255,255, .5)',
    '&:hover': {
      color: 'rgba(255,255,255, .85)',
    },
  },
}))(IconButton);

const styles = {
  cardBody: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 35px',
    borderRadius: '7px',
    margin: '-0.9375rem -20px',
  },
  cardSubBody: {
    padding: '0px',
    '& $cardHeader': {
      fontSize: '18px',
      fontWeight: '450',
    },
  },
  cardHeader: {
    fontSize: '20px',
    marginBottom: '15px',
    letterSpacing: '1px',
    color: '#fff',
    fontWeight: '600',
  },
  cardSubHeader: {
    fontSize: '16px',
    marginBottom: '15px',
    letterSpacing: '1px',
    color: '#fff',
    fontWeight: '600',
  },
  cardDesc: {
    fontWeight: '200',
    letterSpacing: '0.5px',
    color: '#868686',
    marginBottom: '20px',
    whiteSpace: 'pre-wrap',
  },
  params: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '20px',
    '&:last-child': {
      marginBottom: '0px',
    },
  },
  paramsCol: {
    flexDirection: 'column',
    '& $param': {
      width: 'auto',
    },
  },
  param: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0px',
    marginRight: '30px',
    '& > div:first-child': {
      marginRight: '15px',
      fontWeight: '400',
      letterSpacing: '1px !important',
      fontSize: '14px',
      color: grayColor[0],
      // textAlign: "right"
    },
    '& > div:nth-child(2)': {
      maxWidth: '350px',
    },
    '& .MuiInputBase-input': {
      color: whiteColor,
    },
  },
  paramForm: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  arrow: {
    color: blackColor,
  },
  tooltip: {
    backgroundColor: blackColor,
    fontSize: '.75em',
    padding: '8px 16px',
    whiteSpace: 'pre-wrap',
  },
  noCardHeader: {
    margin: 0,
  },
  stratOption: {
    userSelect: 'none',
    fontSize: '13px',
    marginTop: '10px',
    marginRight: '30px',
    display: 'flex',
    cursor: 'pointer',
    border: '1px solid #3e4248',
    borderRadius: '7px',
    overflow: 'hidden',
    backgroundColor: 'rgba(90, 86, 86, 0.9411764705882353)',
    opacity: '0.8',
    '&:hover': {
      opacity: '1',
    },
  },
  stratOpt: {
    borderTopLeftRadius: '7px',
    borderBottomLeftRadius: '7px',
    padding: '7px 10px',
    color: 'white',
    backgroundColor: 'transparent',
    borderRight: '1px solid #3e4248',
  },
  active: {
    border: '2px solid #69abe5',
    fontWeight: '600',
  },
  closeIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '36px',
    height: '100%',
    color: 'red',
    '&:hover': {
      backgroundColor: 'gray',
    },
  },
  copyIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '36px',
    height: '100%',
    color: 'white',
    '&:hover': {
      backgroundColor: 'gray',
    },
  },
  separator: {
    borderBottom: '1px solid',
    borderBottomColor: '#222222',
    margin: '30px 0',
    width: '100%',
  },
  inactive: {
    textDecorationLine: 'line-through',
    color: 'white',
  },
};

class SettingPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.generateState(this.props.data);
    this.state.open = false;
    this.state.options = [];
  }
  static defaultProps = {
    data: [],
    title: '',
    description: '',
    noCardHeader: true,
    direction: 'row',
    isSub: false,
    labelWidth: 'auto',
  };

  componentDidUpdate(prevProps) {
    if (!Object.is(prevProps.data, this.props.data)) {
      this.setState(this.generateState(this.props.data));
    }
  }

  parseInput(value) {
    // don't convert input ending with . to number
    if (typeof autoParse(value) == 'number' && value.toString().endsWith('.')) {
      return value;
    } else if (typeof autoParse(value) == 'number' && value.toString() === '-0') {
      return value;
    }
    // convert to number if value is recognized as number
    else if (typeof autoParse(value) == 'number') {
      return Decimal(value).toNumber();
    } else if (typeof autoParse(value) == 'boolean') {
      return autoParse(value);
    }
    // save as string if input is not recognized as number
    else {
      return value;
    }
  }
  createElements(data, classes, parent = null) {
    function BreakLabel(props) {
      let chunks = props.chunks;
      const chunksLength = chunks.length;
      let underScoreWBR = [];

      chunks.map((piece, i) => {
        underScoreWBR.push(piece);
        if (chunksLength !== i + 1) {
          underScoreWBR.push(<wbr />);
          underScoreWBR.push(`_`);
        }
      });

      return <label>{underScoreWBR}</label>;
    }
    return data.map((item, key) => {
      let inputType;
      if (item.hide === true) {
        return null;
      }
      if (item.hiddenKey) {
        if (item.hiddenKey !== item.name && this.state[item.hiddenKey] === false) {
          return null;
        }
      }
      var inputElement = null;
      let labelElement = null;

      if (item.label) {
        let labelStyle = {
          padding: '5px 0',
        };
        if (item.labelWidth) {
          labelStyle.width = item.labelWidth;
        }
        let labelCaption = item.label.replace('_', '<wbr>_');
        let labelWithBreaks = item.label.split('_');
        labelElement = item.tooltip ? (
          <Tooltip
            arrow
            title={item.tooltip}
            placement="top"
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
          >
            <div style={labelStyle}>{item.label}</div>
          </Tooltip>
        ) : (
          <div style={labelStyle}>{item.label}</div>
        );
      }

      var valueStyle = {};
      if (item.valueWidth) {
        valueStyle.width = item.valueWidth;
      } else {
        valueStyle.flex = 'auto';
      }

      if (item.type === 'form') {
        return (
          <div className={'SP-paramForm ' + classes.paramForm} key={item.name}>
            <div style={{ marginRight: '20px', display: 'flex' }}>
              {this.createElements(item.children, classes, item.name)}
            </div>
            <Button
              variant="contained"
              color="primary"
              style={{ width: '100%', marginBottom: '15px' }}
              onClick={() => {
                if (item.onClick) item.onClick();
              }}
            >
              {item.label}
            </Button>
          </div>
        );
      }
      // Pair selection field
      else if (item.name === 'pairSelect') {
        // just some random functions to simulate async content
        let active = true;
        function sleep(delay = 0) {
          return new Promise(resolve => {
            setTimeout(resolve, delay);
          });
        }

        (async () => {
          if (this.state.options.length === 0) {
            const response = [];
            await sleep(1e3);
            const countries = response;

            if (active) {
              this.setState({ options: response });
            }
          }
        })();

        const loading = this.state.open && this.state.options.length === 0;

        inputElement = (
          <Autocomplete
            id={item.name + '_select'}
            options={this.state.options.length === 0 ? loading : this.state.options}
            getOptionLabel={option => (option ? option.toString() : '')}
            freeSolo={true}
            value={this.getValue(item.name, parent)}
            onChange={(event, value) => {
              this.onChange(value, item, parent);
            }}
            onInputChange={(event, value) => {
              this.onChange(value, item, parent);
            }}
            style={{ width: 200, color: grayColor[6] }}
            renderInput={params => (
              <TextField
                {...params}
                label="Select"
                variant="outlined"
                margin="normal"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {this.state.options.length === 0 ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            renderOption={(option, { inputValue }) => {
              const matches = match(option, inputValue);
              const parts = parse(option, matches);

              return (
                <div>
                  {parts.map((part, index) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                  ))}
                </div>
              );
            }}
          />
        );
      }

      // Combobox for Autoconfig job types
      else if (item.name === 'jobType') {
        inputElement = (
          <Autocomplete
            id={item.name + '_select'}
            options={item.options}
            getOptionLabel={option => (option ? option.toString() : '')}
            value={this.getValue(item.name, parent)}
            onChange={(event, value) => {
              this.onChange(value, item, parent);
            }}
            style={{ width: 300, color: grayColor[6] }}
            renderInput={params => <TextField {...params} label="Select" variant="outlined" margin="normal" />}
            renderOption={(option, { inputValue }) => {
              const matches = match(option, inputValue);
              const parts = parse(option, matches);

              return (
                <div>
                  {parts.map((part, index) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                  ))}
                </div>
              );
            }}
          />
        );
      } else if (item.type === 'button') {
        inputElement = (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (item.onClick) item.onClick();
            }}
          >
            {this.getValue(item.name, parent)}
          </Button>
        );
      } else if (item.type === 'separator') {
        return <div className={classes.separator} key={item.type + key}></div>;
      } else if (item.type === 'subHeader') {
        return (
          <div>
            <span className={classes.cardSubHeader}>{item.label}</span>
          </div>
        );
      } else if (item.type === 'component') {
        return (
          <div key={item.type + key} style={{ width: '100%' }}>
            {item.component}
          </div>
        );
      } else if (Array.isArray(item.value)) {
        return (
          <div style={{ width: '100%' }} key={item.name + '_' + key}>
            {labelElement}
            <div style={{ width: 'auto', display: 'flex', flexWrap: 'wrap' }}>
              {item.value.map((value, index) => {
                return (
                  <div
                    className={
                      classes.stratOption +
                      ' ' +
                      (value === item.current ? classes.active : '') +
                      (!item.active ? '' : item.active[value] ? '' : classes.inactive)
                    }
                    key={item.name + '_' + key + index}
                  >
                    <span
                      className={classes.stratOpt}
                      onClick={() => {
                        if (item.onClick) item.onClick(value);
                      }}
                    >
                      {value}
                    </span>

                    {item.copy === true ? (
                      <span
                        className={classes.copyIcon}
                        onClick={() => {
                          if (item.onCopy) item.onCopy(value);
                        }}
                      >
                        <CopyIcon />
                      </span>
                    ) : null}

                    {item.removable ? (
                      <span
                        className={classes.closeIcon}
                        onClick={() => {
                          if (item.onRemove) item.onRemove(value);
                        }}
                      >
                        <ClearIcon />
                      </span>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>
        );
      } else if (typeof item.value == 'boolean') {
        inputElement = (
          <Switch
            checked={this.getValue(item.name, parent)}
            onChange={event => {
              this.onChange(event.target.checked, item, parent);
            }}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        );
      }

      // Combobox for items with freesolo property
      else if (Array.isArray(item.options) && item.options.length > 4 && item.freeSolo === true) {
        inputElement = (
          <Autocomplete
            id={item.name + '_select'}
            options={item.options}
            getOptionLabel={option => (option ? option.toString() : '')}
            value={this.getValue(item.name, parent)}
            onInputChange={(event, value) => {
              this.onInputChange(value, item, parent);
            }}
            onChange={(event, value) => {
              this.onChange(value, item, parent);
            }}
            freeSolo={true}
            style={{ width: 200, color: grayColor[6] }}
            renderInput={params => <TextField {...params} label="Select" variant="outlined" margin="normal" />}
            renderOption={(option, { inputValue }) => {
              const matches = match(option, inputValue);
              const parts = parse(option, matches);
              return (
                <div>
                  {parts.map((part, index) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                  ))}
                </div>
              );
            }}
          />
        );
      }

      // Combobox for dropdowns with more than 4 non numerical items
      else if (Array.isArray(item.options) && item.options.length > 4 && typeof item.options[0] !== 'number') {
        const settingsOptions = item.options.filter(option => !_.isNil(option));
        inputElement = (
          <Autocomplete
            id="free-solo-demo"
            freeSolo
            options={settingsOptions}
            renderInput={params => <TextField {...params} label="Select" margin="normal" variant="outlined" />}
            onChange={(event, value) => {
              this.onChange(value, item, parent);
            }}
            onInputChange={(event, value) => {
              this.onInputChange(value, item, parent);
            }}
          />
        );
      }

      // regular dropdown menu
      else if (Array.isArray(item.options)) {
        inputElement = (
          <Select
            labelId={item.name + '_select_label'}
            id={item.name + '_select'}
            value={this.getValue(item.name, parent)}
            onChange={event => {
              this.onChange(event.target.value, item, parent);
            }}
            displayEmpty
          >
            <MenuItem value="">
              <span>
                <em style={{ color: grayColor[6] }}>Please Select</em>
              </span>
            </MenuItem>
            {(() => {
              return item.options.map(option => (
                <MenuItem value={option} style={{ color: grayColor[5] }} key={`${item.name}_${option}`}>
                  <span style={{ color: grayColor[5] }}>{option}</span>
                </MenuItem>
              ));
            })()}
          </Select>
        );
      } else {
        /* todo: add pattern /^[13][a-km-zA-HJ-NP-Z0-9]{26,33}$/ for btc address */

        let type = isNumber(item.value) ? 'number' : 'text';
        inputType = 'textInputGrid';
        inputElement = (
          <TextField
            label=""
            type={type}
            inputProps={{
              readOnly: item.readonly,
              inputMode: type === 'number' ? 'numeric' : 'text',
              pattern: type === 'number' ? '[0-9]*' : '',
            }}
            fullWidth={false}
            multiline={false}
            // style={{ width: item.valueWidth || (type === "number" ? 60 : 300) }}
            value={this.getValue(item.name, parent)}
            placeholder={item.placeholder ? item.placeholder : ''}
            disabled={item.disabled}
            onChange={event => {
              let value = event.target.value;
              if (type === 'number') value = +value;
              this.onChange(value, item, parent);
            }}
          />
        );
      }

      let style = {};

      if (typeof inputType === 'undefined') {
        style = {
          //display: "flex"
        };
      }
      if (item.width) {
        style.width = item.width;
      }
      if (item.marginTop) {
        style.marginTop = item.marginTop;
      }
      var removeElement = null;
      if (item.removable) {
        removeElement = (
          <span
            className={classes.closeIcon}
            onClick={() => {
              if (item.onRemove) item.onRemove();
            }}
          >
            <ClearIcon />
          </span>
        );
      }
      return (
        <div className={'SP-param ' + classes.param} style={style} key={item.name + key}>
          {labelElement}
          <div style={valueStyle}>{inputElement}</div>
          {removeElement}
        </div>
      );
    });
  }
  onChange(value, item, parent) {
    this.setValue(value, item.name, parent);
    if (item.onChanged) {
      item.onChanged(value, item);
    }
  }
  onInputChange(value, item, parent) {
    this.setValue(value, item.name, parent);
    if (item.onChanged) {
      item.onInputChanged(value, item);
    }
  }
  helpButton(title) {
    if ($scope.tabDescriptionLinks[title]) {
      return (
        <BlackIconButton
          onClick={() => {
            window.open($scope.tabDescriptionLinks[title], '_blank');
          }}
        >
          <HelpIcon />
        </BlackIconButton>
      );
    }
  }
  render() {
    // this.state = this.generateState(this.props.data)
    var { classes, data, title, tabDescription, description, noCardHeader, direction, isSub, hidden, selectedJobType } =
      this.props;
    if (hidden) return null;
    var style = {};
    if (isSub) {
      style.paddingLeft = '0px';
      style.paddingRight = '0px';
    }

    // use general tab description when available, else tabDescriptionsAutoconfigOutput is used by default
    if ($scope.tabDescriptions[title]) {
      tabDescription = $scope.tabDescriptions[title];
    }
    return (
      <div
        className={
          classes.cardBody +
          ' ' +
          (noCardHeader ? classes.noCardHeader : '') +
          ' ' +
          (isSub ? classes.cardSubBody : '') +
          (this.props.className ? ' ' + this.props.className : '')
        }
      >
        {/*title !== "" ? <span className={classes.cardHeader}>{title}</span> : null*/}
        <span className={classes.cardDesc}>
          {tabDescription}

          {/*this.helpButton(title)*/}
        </span>
        <div className={'SP-params ' + classes.params + ' ' + (direction === 'row' ? '' : classes.paramsCol)}>
          {this.createElements(data, classes)}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(SettingPanel);
